:root{
  --orange-peel: hsla(32, 100%, 63%, 1);
  --light-cyan: hsla(192, 96%, 90%, 1);
  --alice-blue: hsla(191, 100%, 98%, 1);
  --van-dyke: hsla(11, 16%, 20%, 1);
  --redwood: hsla(14, 30%, 43%, 1);
  --chefchaouen-blue: hsla(214, 77%, 60%, 1);
  --mulberry: hsla(332, 39%, 55%, 1);

}

/* https://coolors.co/ffa640-ccf4fe-f4fdff-3b2e2b-8d5b4c */

/* https://coolors.co/ffa640-ccf4fe-f4fdff-4a8fe7-b95f89 */

*{
  margin: 0;
  padding: 0;
}

a{
  text-decoration: none;
}

a:visited{
  color: var(--alice-blue);
}

li{
  list-style: none;
}

h2{
  padding: 1rem 0;
}

h3{
  padding: 1rem 0;
}

.App {
  background-color: var(--alice-blue);
  color: var(--van-dyke);
  text-align: center;
  font-family: "Noto Sans TC", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;


}

.App-header, .App-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin: 1rem 0; */
  
}

nav{
  padding: 1rem ;
}

.navi{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 1.5rem;
  
}

.navi li{
  padding: 0 1rem;
 
}



.navi li a:visited{
  color: var(--van-dyke);
}


/* Hero欄位介紹 */

.hero-div, .feature-div {
  
 
  width: 90%;
  padding: 1rem 0;
  margin: 1rem 0;
}


.speech-bubble {
	position: relative;
	background: var(--orange-peel);
	border-radius: 10px;
  width: 80%;
  margin: 0 0 0 1rem;
  padding: 1rem 0;
  border: 3px solid var(--van-dyke);
}

.speech-bubble:after {
	content: '';
	position: absolute;
	bottom: -2rem;
	left: 80%;
  width: 5rem;
  height: 2rem;
  background: var(--orange-peel);
  clip-path: polygon(35% 0, 92% 90%, 5% 0);
}

.arrow:after{
  content: '';
	position: absolute;
	bottom: -2rem;
	left: 80%;
  width: 5rem;
  height: 2rem;
  background: var(--van-dyke);
  clip-path: polygon(40% 0, 100% 100%, 0 0);

}


.hero-resume-div{
  margin: 3rem auto 1rem auto;

}

.hero-resume-ul{
  display: flex;
  align-items: center;
  justify-content: center;
}


.hero-resume-ul li{
  padding: 0.5rem 1rem;
  margin: 0 1rem;
  border-radius: 5px;
  color: var(--alice-blue);
  background-color: var(--redwood);
  
}

.hero-resume-ul li a{
  color:var(--alice-blue);
}

.hero-resume-ul li:hover{
  background-color: var(--orange-peel);

}

.hero-resume-ul li:hover a{
  color: var(--van-dyke);
}


/* 關於我 */

.feature-div{
  width: 100%;
}

.feature-ul  {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;
  
}
.feature-ul{
  padding: 0 ;
}




.feature-ul > li{
  width: 100%;
  height: 25rem;
}




.slideImage{

  display: none;
 
}


.sticky{
  position: sticky;
  top: 0;
  
}

.feature-slide-div{
  margin: 1rem 0; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}

.feature-slide-div > div{
  margin: auto 0;
}



.feature-slide-detail-ul >li{
  margin: 1rem 0;
}

#firstitem{
  background-color: var(--chefchaouen-blue);
  z-index:2;
  height: 30rem;
}


#seconditem{
  background-color: var(--mulberry);
  z-index: 3;
}

#thirdItem{
  background-color: var(--orange-peel);
  z-index: 4;
  height: 35rem;

}

/* 作品集 */

.project-div{
  width: 100%;
}

.project-ul{
  padding: 0 1rem;
 
  
}


 

.project-ul > li{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 0;
  
  width: 100%;
  height: 100%;
  margin-bottom: 1rem;

  background-color: var(--light-cyan);
  border-radius: 10px;
  box-shadow: 5px 5px 10px var(--redwood);

  
}



.project-li-div > div{

  padding: 0.5rem 0;

}

.project-li-div div:nth-child(2){

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


}

.project-li-div div:nth-child(2) p{
  padding: 0.5rem;
  margin: 0.5rem 0;
  
}

.project-li-div hr{
  width: 80%;
  
}

.project-a ul{
  display: flex;
  flex-direction: row;
  

}

.project-a  ul > li  {
  cursor: pointer;
  margin: 0 0.5rem;
  padding: 0.25rem 0.5rem;
  border-radius: 10px;
  
  background-color: var(--redwood);
  

}

.project-a  ul > li a{

  color: var(--alice-blue);
}

.project-a  ul > li:hover{
  
  background-color: var(--orange-peel);
}


.project-a  ul > li:hover a{
  
  color: var(--van-dyke);
  
}

.project-a  ul > li:hover button{
  
  color: var(--van-dyke);
  
}

.project-a ul li  button{
  color: var(--alice-blue);
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  font-size: 1rem;
  font-weight: 500;

}





.project-sub-ul{
  margin-top: 1rem;
  padding: 0 3rem;
  text-align: left;
}

.project-sub-p{
  margin-top: 1rem;
}

.project-sub-ul li{
  list-style: disc;
}


.projectImg{
  width: 100%;
  object-fit: contain;
}

@media (min-width: 800px){

  .projectImg{
   
    height: 15rem;
    
  }

  .project-ul {
    margin: 0 5rem;
  }

  .project-li-div{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .project-li-div > div{
    margin: 0 1rem;
  }

  .project-sub-p {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .project-sub-p > h4{
    width: 40%;
  }

  .project-sub-ul{
    width: 60%;
    margin: 0 auto;
  }

}



@media (min-width: 1200px) {
  
  .hero-div{
    display: flex;
  }

  .hero-resume-ul{

    flex-direction: column;

  }

  .hero-resume-ul li{
    margin: 1rem 0;
    padding: 1rem 2rem;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .speech-bubble {
  
    width: 40%;
    font-size: 1.5rem;
    padding: 2rem 0;


  }




.feature-ul >li{
  height: 25rem;
  padding: 2rem;
  

}



.feature-slide-div{

  flex-direction: row;
  justify-content: space-around;
}

.slideImage{
  width: 25rem;
  height: 20rem;
  object-fit: cover;
  display: block;
}






#thirdItem{

  height: 25rem;

}


/* .sticky{
  position: sticky;
  top: 0;
  
  
} */


}